<template>
  <div
    class="w-block__pre-heading"
    :class="{ 'ml-4 mr-4 mt-3': $voicer.isMobile }"
  >
    <!-- pre-heading block -->
    <v-container v-if="currentPage === 'default'" class="pa-0">
      <v-sheet
        ref="sheet"
        color="primary"
        class="pa-2"
        :class="{
          'v-sheet__desktop': $voicer.isDesktopOrTablet,
          'ma-3 mb-4': $voicer.isDesktopOrTablet,
        }"
      >
        <div class="pl-2 pr-2 pt-1 white--text">
          <strong>
            Posez vos questions pour le prochain Live de la rentrée</strong
          >
        </div>
        <div>
          <v-btn color="accent" @click.stop="showFirstFeedbackModal = true">
            Enregistrez votre question
          </v-btn>
          <w-feedback-popup
            id="firstFeedback"
            v-model="showFirstFeedbackModal"
          />

          <v-btn
            color="accent"
            @click.stop="
              handleOpen('https://app.sli.do/event/3cdfbwH6YjNKyHywzJ5KvU')
            "
          >
            Écrivez votre question
          </v-btn>
          />
        </div>
      </v-sheet>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import WFeedbackPopup from '../components/WFeedbackPopup'

export default {
  components: {
    WFeedbackPopup,
  },
  data() {
    return {
      showFirstFeedbackModal: false,
    }
  },
  computed: {
    ...mapGetters({
      currentPage: 'root/currentPage',
    }),
  },

  methods: {
    handleOpen(url) {
      window.open(url)
    },
  },
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .v-sheet {
    div {
      display: inline;
    }
  }
}
@media screen and (min-width: 601px) {
  .v-sheet {
    div {
      display: flex;
    }
  }
}
.w-block__pre-heading {
  justify-content: center;
  text-align: center;
  font-weight: 500;

  .v-sheet {
    div {
      justify-content: center;
      text-align: center;
    }
    border-radius: 8px;
  }

  .v-btn {
    border-radius: 4px;
    color: black;
  }
}

@media screen and (max-width: 600px) {
  .message_welcome_sir {
    flex-direction: column;
    align-items: center;
    div {
      margin-top: 15px;
    }
  }
}
</style>
